    
    import PropTypes from "prop-types";
    import { useState, useEffect, memo } from "react";
    import { useShallow } from "zustand/react/shallow";
    import { useNavigate } from "react-router-dom";

    // Импортируем компоненты
    import Placeholder from "Skeletons/Chat/Topbar";

    // Импортируем хранилище 
    import Store from "Store";

    // Импортируем стили из index.module.css
    import Style from "./index.module.css";

    const Chat = ({ pathnameBase, pathnameParams, searchParams }) => {
        // Получаем данные из хранилища с помощью хука useShallow
        const { colorScheme, uuid, chat } = Store(
            useShallow(state => ({
                colorScheme: state.user.colorScheme,
                uuid: state.chat.uuid,
                chat: state.chats[state.chat.uuid]
            }))
        );

        // Создаем состояние Topbar и Messages которые будут хранить компоненты Topbar и Messages
        const [ Topbar, setTopbar ] = useState(null);
        const [ Messages, setMessages ] = useState(null);

        // Получаем функцию для перехода по страницам
        const navigate = useNavigate(); // useNavigate вызывает ререндер компонента из-за изменения контекста (и searchParams так же)

        // Динамически загружаем компонент Topbar и Messages при монтировании компонента
        useEffect(() => {
            import("./Topbar").then(module => setTopbar(() => module.default));
            import("./Messages").then(module => setMessages(() => module.default));
        }, []);

        // Отображаем кнопку "Назад" при монтировании компонента
        useEffect(() => {
            // Если uuid не передан, завершаем выполнение функции
            if(!uuid) return;
            
            // Получаем объект BackButton из Telegram.WebApp
            const { BackButton } = window.Telegram.WebApp;
            
            // Показываем кнопку "Назад"
            BackButton.show();

            // Добавляем обработчик клика по кнопке "Назад"
            BackButton.onClick(function() {
                // Скрываем кнопку "Назад"
                BackButton.hide();
            });

            // Обработчик события "backButtonClicked"
            const backButtonClickedEvent = () => {
                // Переходим на главную страницу для удаления параметров из URL
                navigate("/");
            }; 

            // Добавляем обработчик события "backButtonClicked"
            window.Telegram.WebApp.onEvent("backButtonClicked", backButtonClickedEvent);

            // Функция очистки при размонтировании компонента
            return () => {
                // Отписываемся от события "backButtonClicked"
                window.Telegram.WebApp.offEvent("backButtonClicked", backButtonClickedEvent);
            };
        }, [ uuid, navigate ]);
        
        return (
            <div className={Style.chat} data-color-scheme={colorScheme} data-hidden={!uuid || null}>
                {uuid && (
                   chat && Topbar && Messages ? (
                        <>
                            <Topbar 
                                title={chat.title}
                                username={chat.username}
                                accentColor={chat.accentColor}
                                photo={chat.photo}
                            />
                            
                            <Messages
                                /**
                                 * Добавляем ключ к компоненту Messages для его повторного монтирования при изменении идентификатора активного чата.
                                 * Иначе, при обрезке сообщений до 25 при размонтировании компонента, компонент Messages будет повторно рендериться с урезанными сообщениями.
                                */
                                key={uuid}
                                uuid={uuid}
                                title={chat.title}
                                username={chat.username}
                                accentColor={chat.accentColor}
                            />
                        </>
                    ) : (
                        <Placeholder />
                    )
                )}
            </div>
        );
    };

    Chat.propTypes = {
        pathnameBase: PropTypes.string.isRequired,
        pathnameParams: PropTypes.string,
        searchParams: PropTypes.object.isRequired
    };

    export default memo(Chat);