
    import PropTypes from "prop-types";
    import { memo } from "react";

    // Импортируем стили из index.module.css
    import Style from "./index.module.css";

    const Skeleton = ({ count }) => { 
        return (
            <div className={Style.skeleton}>
                {Array.from({ length: count }).map((_, index) => (
                    <div key={index} className={Style.item}>
                        <div className={Style.avatar} />
                        <div className={Style.container}>
                            <div className={Style.title}>
                                <div className={Style.name} />
                                <div className={Style.date} />
                            </div>
                            <div className={Style.message} />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    Skeleton.propTypes = {
        count: PropTypes.string.isRequired
    };

    export default memo(Skeleton);

