
    import { create } from "zustand";
    import { subscribeWithSelector, devtools } from "zustand/middleware";
    
    // Создаем хранилище
    const Store = create(subscribeWithSelector(devtools((set) => ({
        user: {
            // Секретный ключ пользователя
            secret: null,
            // Имя пользователя
            firstName: null,
            // Фамилия пользователя
            lastName: null,
            // Юзернейм пользователя
            username: null,
            // Язык пользователя
            lang: null, 
            // Цветовая схема пользователя, по умолчанию "light"
            colorScheme: "light"
        },
        chat: {
            // Уникальный идентификатор записи чата
            uuid: null,
            /**
             * Объект, в котором будут храниться данные о сообщениях чата
             * @type {{[uuid: string]: Message}}
            */
            messages: {},
            // Состояние загрузки сообщений
            isLoading: false
        },
        /**
          * Объект, в котором будут храниться данные о чатах
          * @type {{[uuid: string]: Chat}}
        */
        chats: {},
        /**
          * Объект, в котором будут храниться данные о стикерах
          * @type {{[uuid: string]: Sticker}}
        */
        stickers: {},
        // Методы для изменения состояния хранилища
        setUserProperty: (properties) => set((state) => ({ user: { ...state.user, ...properties } })),
        setChatProperty: async (properties) => {
            // Возвращаем промис для асинхронного изменения состояния
            return new Promise((resolve) => {
                set((state) => {
                    // Разрешаем промис (за счет принципов работы JavaScript, промис будет разрешен после завершения выполнения функции set) т.е. после изменения состояния и обновления компонента
                    resolve();

                    // Устанавливаем новое состояние, добавляя новые свойства к предыдущему состоянию
                    return {
                        chat: {
                            ...state.chat,
                            ...(properties || {}),
                            messages: {
                                ...state.chat.messages,
                                ...(properties.messages || {})
                            }
                        }
                    };
                });
            });
        },
        updateChats: (chat) => set((state) => {
            // Получаем уникальный идентификатор записи чата из переданных данных
            const uuid = Object.keys(chat).pop() || null;

            // Если uuid не существует в переданных данных, возвращаем текущее состояние без изменений
            if (!uuid || !chat[uuid]) return state;

            // Обновляем или добавляем чат, если uuid существует в переданных данных
            const updatedChat = { ...state.chats[uuid], ...chat[uuid] };

            // Возвращаем новое состояние с обновленным или добавленным чатом
            return { chats: { ...state.chats, [uuid]: updatedChat } };
        }),
        updateStickers: (sticker) => set((state) => {
            // Получаем уникальный идентификатор стикера из переданных данных
            const uuid = Object.keys(sticker).pop() || null;
        
            // Если uuid не существует в переданных данных, возвращаем текущее состояние без изменений
            if (!uuid || !sticker[uuid]) return state;
        
            // Объединяем текущий стикер с новым стикером с помощью глубокого слияния
            const updatedSticker = deepMerge(state.stickers[uuid], sticker[uuid]);
        
            // Возвращаем новое состояние с обновленным или добавленным стикером
            return { stickers: { ...state.stickers, [uuid]: updatedSticker } };
        })
    }))));

    /**
     * Функция для глубокого слияния объектов
     * @param {object} target - Целевой объект
     * @param {object} source - Исходный объект
     * @returns {object} - Результат слияния объектов
    */
    const deepMerge = (target = {}, source = {}) => {
        for (const key in source)
            if (source[key] instanceof Object && key in target) 
                Object.assign(source[key], deepMerge(target[key], source[key]));

        return { ...target, ...source };
    };

    export default Store;