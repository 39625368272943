
    import PropTypes from "prop-types";
    import { useState, useEffect, memo } from "react";

    // Импортируем компоненты
    import Placeholder from "Skeletons/ChatList/Item";
    
    // Импортируем хранилище
    import Store from "Store";

    // Импортируем доступные функции из index.utils.js
    import { getChatList } from "./index.utils";

    // Импортируем стили из index.module.css
    import Style from "./index.module.css";

    const ChatList = ({ pathnameBase, pathnameParams, searchParams }) => {
        // Получаем секретный ключ из хранилища
        const secret = Store(state => state.user.secret);
        
        // Получаем уникальный идентификатор записи активного чата из параметров URL
        const uuid = searchParams.get("chat");

        // Создаем состояние chats, которое будет хранить список чатов
        const [ chats, setChats ] = useState(null);

        // Создаем состояние Item в котором будет храниться компонент Item
        const [ Item, setItem ] = useState(null);

        // Динамически загружаем компонент Item при монтировании компонента
        useEffect(() => {
            import("./Item").then(module => setItem(() => module.default));
        }, []);

        // Обновляем информацию о активном чате в хранилище если uuid отсутствует
        useEffect(() => {
            // Если uuid не пустой, завершаем выполнение функции
            if(uuid) return;

            // Получаем данные о чате из хранилища и функцию setChatProperty
            const { chat, setChatProperty } = Store.getState();

            // Если идентификатор активного чата не пустой и uuid пустой, устанавливаем идентификатор активного чата в null
            if(chat.uuid && !uuid) setChatProperty({ uuid: null }); 
        }, [ uuid ]);

        useEffect(() => {
            // Получаем initDataUnsafe, HapticFeedback и ready из Telegram.WebApp
            const { initDataUnsafe, HapticFeedback, ready } = window.Telegram.WebApp;

            // Если initDataUnsafe пустой или secret не передан, завершаем выполнение функции
            if(Object.keys(initDataUnsafe).length === 0 || !secret) return;

            // Получаем список чатов
            getChatList(initDataUnsafe, secret).then(chats => {
                // Если chats не является массивом
                if(!Array.isArray(chats))
                    throw new Error("Chat list is not an array", chats);

                // Устанавливаем полученный список чатов в chats
                setChats(chats);

                // Вызываем ready для завершения загрузки WebApp
                ready && ready();

                // Вызываем HapticFeedback
                HapticFeedback && HapticFeedback.impactOccurred("soft");
            }).catch((error) => {
                // Получаем функции из Telegram.WebApp
                const { HapticFeedback, showPopup, close } = window.Telegram.WebApp;

                // Если сессия устарела, показываем попап с предупреждением
                if(error.message === "The session is outdated") {
                    showPopup?.({
                        title: "Session expired",
                        message: "Please close your Vault and reopen to continue using it.",
                        buttons: [
                            { type: "destructive", id: "closeVault", text: "Close Vault" },
                            { type: "cancel" }
                        ]
                    }, buttonId => {
                        // Закрываем Vault при нажатии на кнопку "Close Vault"
                        buttonId === "closeVault" && close?.();
                    });
                }

                // Вибрация при открытии попапа
                HapticFeedback && HapticFeedback.notificationOccurred("warning");

                // Выводим ошибку в консоль и устанавливаем chats в false
                setChats(false) || console.error(error);
            });
        }, [ secret ]);

        // Если chats равен null или компонент Item не загружен, возвращаем скелетон
        if (chats === null || !Item) {
            return (
                <div className={Style["chat-list"]}>
                    <Placeholder count="8" />
                </div>
            );
        }

        // Если chats является пустым массивом, возвращаем сообщение о том, что у пользователя нет активных чатов
        if (Array.isArray(chats) && chats.length === 0) {
            return (
                <div className={Style["chat-list"]}>
                    <div className={Style["message"]}>
                        <p>You currently have no active chats</p>
                        <a href="https://telegra.ph/How-to-add-a-bot-to-your-Telegram-business-account-05-02" style={{ color: "var(--default-link-color)" }}>How to add a bot to your Telegram business account?</a>
                    </div>
                </div>
            );
        }

        // Если chats равен false, возвращаем сообщение об ошибке
        if (chats === false) {
            return (
                <div className={Style["chat-list"]}>
                    <div className={Style["message"]}>An error occurred while loading the chat list</div>
                </div>
            );
        }

        // Если chats является массивом и не пустой, а компонент Item загружен, возвращаем список чатов
        if(Array.isArray(chats) && chats.length > 0 && Item) {
            return (
                <div className={Style["chat-list"]} data-hidden={uuid && Object.values(chats).some(chat => chat.uuid === uuid)}>
                    <ul className={Style.body}>
                        {chats.map(({ uuid: chat__uuid, title, username, accent_color, photo, date, is_forwarded, is_reply, is_quote, is_sticker, text }) => (
                            <Item key={chat__uuid} uuid={chat__uuid} title={title} username={username} accentColor={accent_color} photo={photo} date={date} isForwarded={is_forwarded} isReply={is_reply} isQuote={is_quote} isSticker={is_sticker} text={text} isActive={chat__uuid === uuid} />
                        ))}
                    </ul>
                </div>
            );
        }
    };

    ChatList.propTypes = {
        pathnameBase: PropTypes.string.isRequired,
        pathnameParams: PropTypes.string,
        searchParams: PropTypes.object.isRequired
    };

    export default memo(ChatList);