    
    import ReactDOM from "react-dom/client";
    import { useEffect } from "react";
    import { BrowserRouter, useLocation, useSearchParams } from "react-router-dom";

    // Импортируем компоненты
    import ChatList from "Components/ChatList";
    import Chat from "Components/Chat";

    // Импортируем хранилище 
    import Store from "Store";

    // Импортируем доступные функции из index.utils.js
    import { setVhProperty, getIcons, parseLocation } from "./index.utils";

    // Импортируем стили из index.css
    import "./index.css";

    const App = () => {
        // Получаем базовый путь и параметры из текущего URL
        const { pathnameBase, pathnameParams } = parseLocation(useLocation());

        // Получаем параметры из текущего URL
        const [ searchParams ] = useSearchParams();

        useEffect(() => {
            // Получаем функции из Telegram.WebApp
            const { enableClosingConfirmation, disableVerticalSwipes } = window.Telegram.WebApp;

            // Включаем подтверждение закрытия приложения
            enableClosingConfirmation && enableClosingConfirmation();

            // Отключаем вертикальные свайпы
            disableVerticalSwipes && disableVerticalSwipes();
        }, []);

        useEffect(() => { 
            // Получаем данные о пользователе из хранилища и функцию setUserProperty
            const { user, setUserProperty } = Store.getState();

            // Получаем initDataUnsafe, HapticFeedback, showPopup, openTelegramLink, close из Telegram.WebApp
            const { initDataUnsafe, HapticFeedback, showPopup, openTelegramLink, close } = window.Telegram.WebApp;

            // Извлекаем start_param, first_name, last_name, username из initDataUnsafe
            const { start_param, user: { first_name, last_name, username } } = initDataUnsafe;

            // Если start_param не передан, показываем попап с предупреждением и кнопкой для перехода к боту
            if (!start_param) {
                showPopup?.({
                    title: "Attention required",
                    message: "It looks like you tried to launch this web application via a direct link. For proper functionality, please launch it directly through our bot in Telegram.",
                    buttons: [
                        { type: "destructive", id: "close", text: "Close" },
                        { type: "default", id: "goToBot", text: "Go to Bot" }
                    ]
                }, buttonId => {
                    buttonId === "goToBot" && openTelegramLink?.("https://t.me/echovaultbot"); // Открываем ссылку на бота в Telegram при нажатии на кнопку "Go to Bot"
                    if (buttonId === "goToBot" || buttonId === "close") close?.(); // Закрываем попап при нажатии на кнопку "Go to Bot" или "Close"
                });

                // Вибрация при открытии попапа
                HapticFeedback && HapticFeedback.notificationOccurred("warning");
            }

            // Объект для хранения обновленных свойств пользователя
            const updatedProperties = {};
        
            // Проверяем формат secret и устанавливаем его, если он изменился
            if (/^[0-9a-fA-F]{64}$/.test(start_param) && start_param !== user.secret) 
                updatedProperties.secret = start_param;

            // Проверяем имя и устанавливаем его, если оно изменилось
            if(first_name && first_name !== user.firstName)
                updatedProperties.firstName = first_name;

            // Проверяем фамилию и устанавливаем ее, если она изменилась
            if(last_name && last_name !== user.lastName) 
                updatedProperties.lastName = last_name;

            // Проверяем имя пользователя и устанавливаем его, если он изменился
            if(username && username !== user.username) 
                updatedProperties.username = username;

            // Устанавливаем обновленные свойства пользователя
            Object.keys(updatedProperties).length > 0 && setUserProperty(updatedProperties);
        }, []);
        
        useEffect(() => {
            // Получаем данные о пользователе из хранилища и функцию setUserProperty
            const { user, setUserProperty } = Store.getState();

            // Поддерживаемые языки
            const supportedLanguages = ["ru", "en", "uk"];

            // Получаем язык браузера
            const userLanguage = navigator.language || navigator.userLanguage;
        
            // Получаем initDataUnsafe
            const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};
        
            // Получаем языковой код из initDataUnsafe или языка браузера, проверяем поддержку и устанавливаем значение по умолчанию
            const languageCode = initDataUnsafe.user?.language_code || (supportedLanguages.includes(userLanguage) ? userLanguage : "en");
        
            // Устанавливаем язык, если он изменился
            languageCode !== user.lang && setUserProperty({ lang: languageCode });
        }, []);

        useEffect(() => {
            // Функция для обработки события изменения темы
            const handleThemeChange = () => {
                // Получаем цветовую схему из Telegram.WebApp
                const { colorScheme } = window.Telegram.WebApp;

                // Получаем данные о пользователе из хранилища и функцию setUserProperty
                const { user, setUserProperty } = Store.getState();
                
                // Устанавливаем цветовую схему, если она изменилась
                if (colorScheme && colorScheme !== user.colorScheme) setUserProperty({ colorScheme });
            };
            
            // Вызываем функцию handleThemeChange при монтировании компонента 
            handleThemeChange();

            // Добавляем обработчик события "themeChanged"
            window.Telegram.WebApp.onEvent("themeChanged", handleThemeChange);
            
            // Удаляем обработчик события при размонтировании компонента
            return () => window.Telegram.WebApp.offEvent("themeChanged", handleThemeChange); 
        }, []);

        // Используем useEffect для загрузки иконок, добавления и удаления обводки, обновления значения --vh
        useEffect(() => {
            // Вызываем функцию getIcons() для загрузки иконок
            getIcons();

            // Добавляем обработчик resize, который обновляет значение --vh
            window.addEventListener("resize", setVhProperty);

            // Вызываем событие resize для обновления значения --vh
            window.dispatchEvent(new Event("resize"));
            
            // Удаляем слушатели событий при размонтировании компонента
            return () => window.removeEventListener("resize", setVhProperty);
        }, []);

        return (
            <>
                <ChatList pathnameBase={pathnameBase} pathnameParams={pathnameParams} searchParams={searchParams} />
                <Chat pathnameBase={pathnameBase} pathnameParams={pathnameParams} searchParams={searchParams} />
            </>
        );
    };

    // Создаем корневой элемент для приложения
    const root = ReactDOM.createRoot(document.getElementById("root"));
    
    // Рендерим приложение в корневой элемент с помощью BrowserRouter
    root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
    
    
    // Создаем корневой элемент для зависимостей (иконки, уведомления)
    const dependencies = ReactDOM.createRoot(document.getElementById("dependencies"));
    dependencies.render();
    