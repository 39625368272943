
    /** 
     * Функция debounce(callback, delay) возвращает функцию, которая вызывает callback через delay миллисекунд после последнего вызова.
     * @param {function} callback - Функция, которую нужно вызвать.
     * @param {number} delay - Задержка перед вызовом функции.
     * @returns {function} - Функция, которая вызывает callback через delay миллисекунд после последнего вызова.
    */
    const debounce = (callback, delay) => {
        // Создаем переменную для хранения таймера
        let timer;
        
        // Возвращаем функцию, которая вызывает callback через delay миллисекунд после последнего вызова
        return function(event) {
            // Если таймер существует, очищаем его
            if(timer) clearTimeout(timer);

            // Устанавливаем новый таймер
            timer = setTimeout(callback, delay, event);
        };
    };
    
    // Установка значения переменной --vh в CSS
    const setVhProperty = debounce(() => document.documentElement.style.setProperty("--vh", (window.innerHeight * 0.01) + "px"), 100);

    /**
     * Функция загружает файл с иконками и добавляет его в DOM дерево страницы.
     * @returns {Promise<void>} - Промис без возвращаемого значения.
    */
    const getIcons = async () => {
        try {
            // Если иконки еще не загружены
            if (!document.getElementById("__svg__icons__dom__")) {
                // Загрузите файл с иконками
                const response = await fetch("/images/icons.svg");
                const text = await response.text();

                // Добавление иконок в DOM дерево страницы внутри контейнера с id="dependencies"
                const divElement = document.getElementById("dependencies");
                divElement.insertAdjacentHTML("beforeend", text);
            }
        } catch (error) {
            // Выводим сообщение об ошибке в консоль
            console.error("An error occurred while loading the icons", error);
        }
    };

    /**
     * Функция разбивает путь на базовую часть и параметры.
     * @param {string} pathname - Путь, который нужно разбить.
     * @returns {object} - Объект с двумя свойствами: pathnameBase и pathnameParams.
    */
    const parseLocation = ({ pathname }) => {
        // Разбиваем путь на части
        const segments = pathname.split("/");

        // Возвращаем объект с двумя свойствами: pathnameBase и pathnameParams
        return { 
            // Базовая часть пути (например, "/about") или "/" по умолчанию
            pathnameBase: `/${segments[1]}` || "/",
            // Параметры пути (например, "1/2/3") или null по умолчанию
            pathnameParams: segments.slice(2).join("/") || null
        };
    };
    
    export { setVhProperty, getIcons, parseLocation };