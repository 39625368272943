
    /**
     * Функция для получения списка чатов
     * @param {object} session - Сессия пользователя
     * @param {string} secret - Секретный ключ пользователя
     * @returns {Promise<object>} - Список чатов
    */
    const getChatList = async (session, secret) => {
        // Если сессия или секретный ключ не переданы - выбрасываем ошибку
        if(!session || Object.keys(session).length === 0 || !secret) 
            throw new Error("Session and secret are required");
        
        // Получаем список чатов
        return fetch("https://api.echovault.app/getChatList", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ session, secret })
        }).then(response => {
            // Если код ответа не равен 200, выбрасываем ошибку
            if (response.status !== 200) {
                return response.json().then(data => {
                    // Выводим ошибку из ответа или стандартное сообщение об ошибке
                    throw new Error(data.error || "Unexpected error occurred while loading chat list");
                });
            }

            // Возвращаем полученные данные в формате JSON
            return response.json();
        }).catch(error => {
           // Возвращаем ошибку
           throw error;
        });
    };

    export { getChatList };