
    import { memo } from "react";

    // Импортируем стили из index.module.css
    import Style from "./index.module.css";

    const Skeleton = () => {
        return (
            <div className={Style.skeleton}>
                <div className={Style.profilePicture}></div>
                <div className={Style.container}>
                    <div className={Style.title}></div>
                    <div className={Style.username}></div>
                </div>
            </div>
        );
    };


    export default memo(Skeleton);

